<template>
    <div class="p-4">
        <loader
            class="z-50"
            :loading="isLoading"
            :backdrop="true"
        />
        <div class="w-full bg-white flex flex-col flex-1 flex fixed bottom-0 p-4 mx-4">
            <h2 class="font-bold text-start my-4 text-base">
                Application status:
                <span class="text-purple-600">{{ application_status }}</span>
            </h2>
        </div>
        <Page
            ref="page"
            title="Rental Application"
            :loading="isLoading || isTPPWidgetVisible"
        >
            <template v-slot:header-addition>
                <div class="w-full bg-white flex flex-col flex-1 py-4 my-4">
                    <h2 class="font-bold text-start my-4 text-xl">Fees and Payments</h2>
                    <h4 class="font-bold text-gray-400 text-start text-sm w-4/5">
                        In order to finish your application process, a credit check must be preformed. This check will be done by a
                        third-party to determine application eligibility. To continue, the application fee and application deposit must be
                        collected.
                    </h4>
                </div>
            </template>
            <div class="fees">
                <h2 class="font-bold text-start my-4 text-l">Charges</h2>
                <div
                    v-for="charge in charges_codes"
                    v-bind:key="charge.id"
                    class="fee"
                >
                    <div class="fee-box">
                        <span class="fee-title">{{ charge.codeCategory.name }}</span>
                        <span class="fee-amount ml-2">${{ charge.amount }}</span>
                    </div>
                </div>
            </div>
            <div v-if="error">
                <span class="error">{{ message }}</span>
            </div>
            <div
                v-else-if="message"
                class="w-4/5 text-start self-start font-bold text-xl mt-4 text-green-700"
            >
                <p>{{ message }}</p>
            </div>
            <div
                v-if="!isLoading"
                class="btn-wrapper mb-4"
            >
                <button
                    class="btn btn-secondary"
                    @click="generateTPPAccessToken"
                >
                    Pay now
                </button>
            </div>
            <div
                id="placePayEmbedded"
                class="w-1/2 mt-3"
            ></div>
        </Page>
    </div>
    <TPPWidgetWrapper
        v-if="isTPPWidgetVisible"
        title="Application Fees Payment"
        :styles="{ height: '656px' }"
    />
</template>

<script>
import Event from '@/utils/EventBus.js';
import Page from '@/components/ui/Page';
import NotifyMixin from '@/mixins/NotifyMixin';
import Loader from '@/components/ui/Loader';
import { mapActions, mapGetters } from 'vuex';
import {
    SOMETHING_WENT_WRONG,
    TRANSACTION_DECLINED,
    UPDATE_APPLICATION_ERROR,
    PAYMENT_ERROR,
    CHARGE_CODES_ERROR,
    PAYMENT_COMPLETED,
    PAYMENT_FAILED,
} from '@/utils/constants/leasing.js';
import TriplePlayPayMixin from '@/mixins/TriplePlayPayMixin';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import TPPWidgetWrapper from '@/components/payments/TPPWidgetWrapper';
import { PAYMENT_METHODS_MAP } from '@/utils/constants/accounting';
import { formatQuotedRentAmount } from '@/utils/Amount';

export default {
    name: 'Payment',
    components: { Page, Loader, TPPWidgetWrapper },
    mixins: [NotifyMixin, TriplePlayPayMixin, AccountingQueryManager],
    props: {
        property: {
            type: Object,
            required: true,
        },

        application: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isLoading: false,
            error: false,
            message: '',
            account: null,
            application_status: 'Application not Submitted',
            charges_codes: null,
            payment_total_fee: 0,
            charge_amounts: [],
            occupancy: null,
            application_record: null,
            paymentData: {
                status: 'processing',
                paymentMethodType: 'credit card',
                accrualAmount: null,
                paymentAmount: null,
                paymentCurrency: 'USD',
                paymentDate: null,
                transactionId: null,
            },
        };
    },

    computed: {
        ...mapGetters({
            getApplication: 'application/getApplication',
            getDocumentInstance: 'application/getDocumentInstance',
            currentProfile: 'auth/currentProfile',
            getApplicationId: 'application/getApplicationId',
            isTPPWidgetVisible: 'accounting/isTPPWidgetVisible',
        }),

        customTPPPaymentConfig() {
            const amount = Number(this.payment_total_fee);
            return {
                paymentType: 'charge',
                amount,
                paymentOptions: ['credit_card'],
            };
        },
    },

    mounted() {
        Event.on('reset-modal', () => {
            this.isLoading = false;
        });
    },

    async beforeMount() {
        await Promise.all([this.fetchCurrentApplicationByContactId(this.currentProfile.contactId), this.fetchChargeCodes()]).catch(() => {
            this.notifyError(SOMETHING_WENT_WRONG);
        });
    },

    methods: {
        ...mapActions({
            setApplication: 'application/setApplication',
            setApplicationId: 'application/setApplicationId',
        }),

        async processPaymentDataBasedOnStatus(data) {
            const isPaymentSuccessful = data?.action === 'success';
            this.paymentData.full_name = `${this.getDocumentInstance?.applicant_first_name} ${this.getDocumentInstance?.applicant_last_name}`;
            this.paymentData.paymentDate = new Date().toISOString();
            this.paymentData.email = this.getDocumentInstance?.current_email;
            this.paymentData.balance = `$${Number(this.payment_total_fee)}`;
            this.paymentData.payment = Number(this.payment_total_fee);
            this.paymentData.ref_number = null;
            this.paymentData.paymentMethodType = PAYMENT_METHODS_MAP[data?.payload?.method];
            if (isPaymentSuccessful) {
                this.message = 'Payment successful';
                this.application_status = 'Background Screening';
                this.paymentData.balance = '$0';
                this.paymentData.payment_status = PAYMENT_COMPLETED;
                this.paymentData.ref_number = data?.payload?.transaction_id;
                /* This will communicate with an Accounting endpoint */
                this.savePayment({ ...this.paymentData }, true);
            } else {
                this.error = true;
                this.paymentData.payment_status = PAYMENT_FAILED;
                this.message = TRANSACTION_DECLINED;
                this.paymentData.ref_number = Math.floor(Math.random() * 9999999999999999);
                this.paymentData.reason = 'Card declined / Invalid';
                Event.emit('reset-modal');
                this.savePayment({ ...this.paymentData });
            }
        },

        formatQuotedRentAmount,

        updateApplicationRecord() {
            this.loading = true;
            const new_application = { ...this.getApplication };
            delete new_application.application_id;
            delete new_application.occupancy_id;
            delete new_application.person_id;
            const currentApplicant = new_application.persons?.find(person => person.contact_id === this.currentProfile.contactId);
            const currentParty = new_application.parties?.find(party => party.contact_id === this.currentProfile.contactId);
            delete new_application.persons;

            this.$leasingServiceDataProvider
                .patch('application', {
                    application_id: currentApplicant?.application[0]?.application.application_id ?? this.getApplicationId,
                    data: {
                        ...new_application,
                        occupancy_id: this.currentProfile.occupancyId,
                        application_process_status: 'Application submitted - Paid',
                        event_description: 'Application has been paid',
                        event_name: 'Application paid',
                        first_name: this.getDocumentInstance.applicant_first_name,
                        last_name: this.getDocumentInstance.applicant_last_name,
                        email: this.getDocumentInstance.current_email,
                        expected_lease_duration: `${this.getApplication.expected_lease_duration}`,
                        quoted_rent_amount: this.formatQuotedRentAmount(this.getApplication.quoted_rent_amount),
                        contact_id: this.getApplication.contact_id ?? new_application.contact_id ?? this.currentProfile.contactId,
                        lease_id: this.currentProfile.occupancyId,
                        middle_name: this.getDocumentInstance.applicant_middle_name ?? '',
                        home_phone: this.getDocumentInstance.applicant_home_phone,
                        document_url: this.getDocumentInstance.document_url,
                        birth_date: this.getDocumentInstance.applicant_birth_date,
                        marital_status: this.mapMaritalStatus(this.getDocumentInstance.applicant_marital_status),
                        sn: this.getDocumentInstance.applicant_ssn.replace(/-/g, ''),
                        coapplicants: this.mapCoApplicants(new_application),
                        occupants: this.mapOccupants(currentApplicant),
                        emergency_contact: this.mapEmergencyContact(currentApplicant),
                        pet: this.mapPets(currentApplicant),
                        vehicle: this.mapVehicles(currentApplicant),
                        submission_date: this.getDocumentInstance.updated_at,
                        current_address: this.getDocumentInstance.current_address ?? '',
                        current_city: this.getDocumentInstance.current_city ?? '',
                        current_st: this.getDocumentInstance.current_st ?? '',
                        current_zip: this.getDocumentInstance.current_zip ?? '',
                        employment_income: 1, // Default value
                        employment_status: this.getDocumentInstance.current_employer ? 1 : 4,
                        is_primary: currentParty?.is_primary,
                        person_role: currentApplicant?.person_role,
                    },
                })
                .then(() => {
                    this.$router.push({
                        name: 'application_service.pre_evaluation_form',
                        params: { propertyId: this.$props.property.id },
                    });
                })
                .catch(() => {
                    this.notifyError(UPDATE_APPLICATION_ERROR);
                })
                .finally(() => (this.loading = false));
        },

        mapEmergencyContact(currentApplicant) {
            const emergencyContact =
                currentApplicant?.emergency_contact?.length &&
                currentApplicant?.emergency_contact[0] &&
                this.getDocumentInstance?.emergency_contact_name
                    ? {
                        person_id: currentApplicant?.emergency_contact[0]?.person_id,
                        contact_id: currentApplicant?.emergency_contact[0]?.contact_id,
                        name: this.getDocumentInstance?.emergency_contact_name,
                        email: this.getDocumentInstance.emergency_contact_email,
                        cell_phone: this.getDocumentInstance.emergency_contact_cell_phone,
                        home_phone: this.getDocumentInstance.emergency_contact_home_phone,
                        relation: this.getDocumentInstance.emergency_contact_relation,
                    }
                    : this.getDocumentInstance?.emergency_contact_name
                        ? {
                            name: this.getDocumentInstance?.emergency_contact_name,
                            email: this.getDocumentInstance?.emergency_contact_email,
                            cell_phone: this.getDocumentInstance.emergency_contact_cell_phone,
                            home_phone: this.getDocumentInstance.emergency_contact_home_phone,
                            relation: this.getDocumentInstance.emergency_contact_relation,
                        }
                        : null;

            return emergencyContact;
        },

        mapOccupants(currentApplicant) {
            const occupants = this.getDocumentInstance?.occupants?.filter(occupant => occupant.name !== null)?.length
                ? this.getDocumentInstance?.occupants
                    ?.filter(occupant => occupant.name !== null)
                    ?.map((occupant, i) => {
                        if (currentApplicant?.occupants?.length && currentApplicant?.occupants[i]?.person_id) {
                            if (
                                `${currentApplicant?.occupants[i]?.first_name} ${currentApplicant?.occupants[i]?.last_name}` ===
                                  occupant.name
                            ) {
                                return {
                                    name: occupant.name,
                                    relation: occupant.relation,
                                    birth_date: occupant.birth_date,
                                    person_id: currentApplicant.occupants[i]?.person_id,
                                    contact_id: currentApplicant.occupants[i]?.contact_id,
                                };
                            }
                        }
                        return {
                            name: occupant.name,
                            relation: occupant.relation,
                            birth_date: occupant.birth_date,
                        };
                    })
                : [];
            return occupants;
        },

        mapVehicles(currenApplicant) {
            const vehiclesDocInstance =
                this.getDocumentInstance?.vehicles && this.getDocumentInstance?.vehicles?.filter(vehicle => vehicle.make !== null)?.length
                    ? this.getDocumentInstance?.vehicles?.filter(vehicle => vehicle.make !== null)
                    : [];
            let vehicles = [];
            switch (true) {
            case vehiclesDocInstance?.length && !currenApplicant?.vehicles?.length:
                vehicles = vehiclesDocInstance.map(vehicle => {
                    return {
                        customer_id: this.getApplication.customer_id,
                        business_unit_id: this.getApplication.business_unit_id,
                        vehicle_make: vehicle.make,
                        vehicle_year: vehicle.year,
                        vehicle_model: vehicle.model,
                        vehicle_color: vehicle.color,
                        license_plate_region: vehicle.license_st,
                        license_plate: vehicle.license_no ?? '',
                        primary_driver_party_id: currenApplicant?.person_id,
                        vehicle_type: 'n/a',
                    };
                });
                break;

            case !vehiclesDocInstance?.length && currenApplicant?.vehicles?.length:
                vehicles = currenApplicant?.vehicles?.length
                    ? currenApplicant?.vehicles.map(vehicle => {
                        return {
                            ...vehicle,
                            primary_driver_party_id: currenApplicant?.person_id,
                        };
                    })
                    : [];
                break;

            default:
                if (vehiclesDocInstance?.length > currenApplicant?.vehicles?.length) {
                    vehicles = vehiclesDocInstance.map((vehicle, i) => {
                        if (currenApplicant?.vehicles[i]?.vehicle_make === vehicle.make) {
                            return {
                                vehicle_id: currenApplicant?.vehicles[i]?.vehicle_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                vehicle_make: vehicle.make,
                                vehicle_year: vehicle.year,
                                vehicle_model: vehicle.model,
                                vehicle_color: vehicle.color,
                                primary_driver_party_id: currenApplicant?.vehicles[i]?.primary_driver_party_id,
                                license_plate_region: vehicle.license_st,
                                vehicle_license_plate_text: vehicle.license_no ?? '',
                                vehicle_type: 'n/a',
                            };
                        }

                        return {
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            vehicle_make: vehicle.make,
                            vehicle_year: vehicle.year,
                            vehicle_model: vehicle.model,
                            vehicle_color: vehicle.color,
                            primary_driver_party_id: currenApplicant?.person_id,
                            license_plate_region: vehicle.license_st,
                            vehicle_license_plate_text: vehicle.license_no ?? '',
                            vehicle_type: 'n/a',
                        };
                    });
                } else {
                    vehicles = currenApplicant?.vehicles?.map((vehicleObj, i) => {
                        if (vehiclesDocInstance[i]?.make === vehicleObj.vehicle_make) {
                            return {
                                vehicle_id: vehicleObj.vehicle_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                vehicle_make: vehiclesDocInstance[i].make,
                                vehicle_year: vehiclesDocInstance[i].year,
                                vehicle_model: vehiclesDocInstance[i].model,
                                vehicle_color: vehiclesDocInstance[i].color,
                                primary_driver_party_id: vehicleObj.primary_driver_party_id,
                                license_plate_region: vehiclesDocInstance[i].license_st,
                                vehicle_license_plate_text: vehiclesDocInstance[i].license_no ?? '',
                                vehicle_type: 'n/a',
                            };
                        }
                        return {
                            vehicle_id: vehicleObj.vehicle_id,
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            vehicle_make: vehicleObj.vehicle_make,
                            vehicle_year: vehicleObj.vehicle_year,
                            vehicle_model: vehicleObj.vehicle_model,
                            vehicle_color: vehicleObj.vehicle_color,
                            primary_driver_party_id: currenApplicant?.person_id,
                            license_plate_region: vehicleObj.license_plate_region,
                            vehicle_license_plate_text: vehicleObj.vehicle_license_plate_text ?? '',
                        };
                    });
                }
                break;
            }

            return vehicles;
        },

        mapPets(currenApplicant) {
            const petsDocInstance =
                this.getDocumentInstance?.pets && this.getDocumentInstance?.pets?.filter(pet => pet.name !== null)?.length
                    ? this.getDocumentInstance?.pets?.filter(pet => pet.name !== null)
                    : [];

            let pets = [];
            switch (true) {
            case petsDocInstance?.length && !currenApplicant?.pets?.length:
                pets = petsDocInstance.map(pet => {
                    return {
                        customer_id: this.getApplication.customer_id,
                        business_unit_id: this.getApplication.business_unit_id,
                        pet_name: pet.name,
                        pet_weight: pet.weight,
                        pet_breed: pet.breed,
                        pet_owner_party_id: currenApplicant?.person_id,
                        pet_species: null,
                        pet_sex: null,
                    };
                });
                break;

            case !petsDocInstance?.length && currenApplicant?.pets?.length:
                pets = currenApplicant?.pets?.length ? currenApplicant?.pets : [];
                break;

            default:
                if (petsDocInstance?.length > currenApplicant?.pets?.length) {
                    pets = petsDocInstance.map((pet, i) => {
                        if (currenApplicant?.pets[i]?.pet_name === pet.name) {
                            return {
                                pet_id: currenApplicant?.pets[i]?.pet_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                pet_name: pet.name,
                                pet_weight: pet.weight,
                                pet_breed: pet.breed,
                                pet_owner_party_id: currenApplicant?.pets[i]?.pet_owner_party_id,
                                pet_species: currenApplicant?.pets[i]?.pet_species,
                                pet_sex: currenApplicant?.pets[i]?.pet_sex,
                            };
                        }

                        return {
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            pet_name: pet.name,
                            pet_weight: pet.weight,
                            pet_breed: pet.breed,
                            pet_owner_party_id: currenApplicant?.person_id,
                            pet_species: null,
                            pet_sex: null,
                        };
                    });
                } else {
                    pets = currenApplicant?.pets?.map((petObj, i) => {
                        if (petsDocInstance[i]?.name === petObj.pet_name) {
                            return {
                                pet_id: petObj.pet_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                pet_name: petsDocInstance[i].name,
                                pet_weight: petsDocInstance[i].weight,
                                pet_breed: petsDocInstance[i].breed,
                                pet_owner_party_id: petObj.pet_owner_party_id,
                                pet_species: petObj.pet_species,
                                pet_sex: petObj.pet_sex,
                            };
                        }
                        return {
                            pet_id: petObj.pet_id,
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            pet_name: petObj.pet_name,
                            pet_weight: petObj.pet_weight,
                            pet_breed: petObj.pet_breed,
                            pet_owner_party_id: currenApplicant?.person_id,
                            pet_species: petObj.pet_species,
                            pet_sex: petObj.pet_sex,
                        };
                    });
                }
                break;
            }
            return pets;
        },

        mapCoApplicants(application) {
            let co_applicants =
                application.persons
                    ?.filter(
                        person => person.person_role.toLowerCase() === 'applicant' && person.contact_id !== this.currentProfile.contactId
                    )
                    ?.map(applicant => {
                        return {
                            person_id: applicant.person_id,
                            name: `${applicant.first_name} ${applicant.last_name}`,
                            email: applicant.email,
                        };
                    }) ?? [];

            const coApplicantsDocInstance = this.getDocumentInstance?.co_applicants?.filter(applicant => applicant.name !== null) ?? [];

            switch (true) {
            case coApplicantsDocInstance?.length && !co_applicants?.length:
                co_applicants = coApplicantsDocInstance;
                break;
            default:
                if (coApplicantsDocInstance?.length > co_applicants?.length) {
                    co_applicants = coApplicantsDocInstance.map((applicant, i) => {
                        if (co_applicants[i]?.email === applicant.email) {
                            return {
                                person_id: co_applicants[i].person_id,
                                name: `${co_applicants[i].first_name} ${co_applicants[i].last_name}`,
                                email: co_applicants[i].email,
                            };
                        }

                        return {
                            name: coApplicantsDocInstance.name,
                            email: coApplicantsDocInstance.email,
                        };
                    });
                }
                break;
            }
            return co_applicants;
        },

        savePayment(payment_info, isPaid = false) {
            this.isLoading = true;

            const payerId = this.getApplication.parties?.find(party => party.contact_id === this.currentProfile.contactId)?.party_id;

            if (!this.charges_codes.length) {
                this.isLoading = false;
            }

            let sum = 0;
            this.charges_codes.forEach(async charge => {
                await this.$accountingServiceDataProvider
                    .create('application_fee', {
                        accountId: this.getApplication.occupancy_id,
                        headers: {
                            customerId: this.currentProfile.customerId,
                            communityId: this.currentProfile.communityId,
                        },
                        data: {
                            codeId: charge?.id,
                            reference: `${payment_info.ref_number}`,
                            payment:
                                payment_info.payment_status === 'FAILED'
                                    ? {
                                        status: payment_info.payment_status,
                                        paymentMethodType: 'credit card',
                                        accrualAmount: Number(charge.amount),
                                        paymentAmount: Number(charge.amount),
                                        paymentCurrency: 'USD',
                                        paymentDate: payment_info.paymentDate,
                                        transactionId: `${payment_info.ref_number}`,
                                        reason: payment_info.reason,
                                        payerId,
                                    }
                                    : {
                                        status: payment_info.payment_status,
                                        paymentMethodType: 'credit card',
                                        accrualAmount: Number(charge.amount),
                                        paymentAmount: Number(charge.amount),
                                        paymentCurrency: 'USD',
                                        paymentDate: payment_info.paymentDate,
                                        transactionId: `${payment_info.ref_number}`,
                                        payerId,
                                    },
                        },
                    })
                    .then(async () => {
                        sum += 1;
                        if (sum === this.charges_codes.length) {
                            if (isPaid) {
                                this.message = '';
                                await this.updateApplicationRecord();
                            }
                        }
                    })
                    .catch(() => {
                        this.notifyError(PAYMENT_ERROR);
                    })
                    .finally(() => (this.isLoading = false));
            });
        },

        async fetchChargeCodes() {
            this.isLoading = true;
            await this.$accountingServiceDataProvider
                .get('charge_codes', {
                    sortDirection: 'DESC',
                    sortField: 'id',
                    chargeCodeCategoryAlias: 'applicant-fee',
                    codeType: 'receivable',
                    headers: {
                        customerId: this.currentProfile.customerId,
                        communityId: this.currentProfile.communityId,
                    },
                })
                .then(res => {
                    this.charges_codes = res.data;
                    res.data.forEach(charge => {
                        this.charge_amounts.push(Number(charge.amount));
                    });
                })
                .catch(() => {
                    this.notifyError(CHARGE_CODES_ERROR);
                })
                .finally(() => (this.isLoading = false));

            this.payment_total_fee = this.charge_amounts.reduce((partialSum, a) => partialSum + a, 0);
        },

        async fetchCurrentApplicationByContactId(contact_id) {
            await this.$leasingServiceDataProvider
                .getOne('application', {
                    contact_id,
                })
                .then(async res => {
                    this.application_record = res[0];
                })
                .catch(error => {
                    this.notifyError(error.message);
                });
        },

        mapMaritalStatus(status) {
            const marital_status = {
                SINGLE: 'Single',
                MARRIED: 'Married',
            }[status];
            return marital_status;
        },
    },
};
</script>

<style scoped>
.fees {
    width: 80%;
    margin: 2.5rem 0 2.5rem;
}
.message {
    text-align: center;
    margin: 1rem 0;
    font-weight: 700;
}
.fees > .fee {
    background: #f5f5f5;
    border: 1px solid #ededed;
    border-radius: 6px;
    padding: 0.75rem 1.25rem;
    align-items: center;
    max-width: 100%;
}
.fees > .fee > .fee-box {
    display: flex;
    justify-content: space-between;
}
</style>
